<template>
  <footer>
    <div class="f-border">
      <div class="red-box"></div>
    </div>
    <div class="footer-content">
      <p v-for="item in pageNameList" :key="item.key" :class="routeName === item.key ? 'page-name active' : 'page-name'">
        <router-link :to="{ name: item.key }">
          {{ item.name }}
        </router-link>
      </p>

      <p class="other-page">
        <router-link :to="{ name: 'MprivacyPolicy' }">Privacy Policy</router-link>
        &nbsp;|&nbsp;
        <router-link :to="{ name: 'MtermsOfUse' }">Terms of Use</router-link>
      </p>
      <p class="reserved">©2024 ArcSoft Corporation Limited.</p>
      <p class="reserved">All rights reserved.</p>
    </div>
  </footer>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const routeName = ref('');
const route = useRoute();

watch(
  route,
  (val) => {
    routeName.value = val.name;
  },
  { deep: true, immediate: true }
);
const pageNameList = [
  {
    name: 'In-cabin Visual Software Solutions',
    key: 'MInCabin',
  },
  {
    name: 'Auto-Parking Assist (APA) Solutions',
    key: 'MAPA',
  },
  {
    name: 'Integrated ADAS Solutions',
    key: 'MADAS',
  },
  {
    name: 'Integrated Solutions for Commercial Vehicles',
    key: 'MCommercialVehicles',
  },
  {
    name: 'Partnership',
    key: 'MPartnership',
  },
  {
    name: 'About us',
    key: 'MAboutUs',
  },
];
</script>
<style lang="less" scoped>
footer {
  background-color: #fafafa;
  padding-bottom: 30px;
  .f-border {
    height: 4px;
    width: 100%;
    background-color: #222222;
    .red-box {
      width: 50%;
      height: 100%;
      margin: 0 auto;
      background-color: #e83021;
    }
  }
  .footer-content {
    text-align: center;
    p {
      margin-top: 16px;
    }
    .active {
      color: #a5a5a5 !important;
    }
    .page-name {
      margin-top: 8px;

      color: #222222;
      a {
        font-size: 14px;
      }
    }
    .zn-en,
    .other-page,
    .reserved {
      font-size: 14px;
      color: #a5a5a5;
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
