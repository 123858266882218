<template>
  <div class="box-radius">
    <div class="top box" @click="scrollToTop">
      <img src="/image/phone/icon/to-top.svg" />
    </div>
    <div class="phone box" v-if="isShow" @click="toPage">
      <img src="/image/phone/icon/phone.svg" />
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, watch } from 'vue';

const router = useRouter();
const route = useRoute();
const isShow = ref(true);
watch(
  route,
  (val) => {
    if (val.name === 'MContact') {
      isShow.value = false;
    } else {
      isShow.value = true;
    }
  },
  { deep: true, immediate: true }
);

const toPage = () => {
  router.push({ name: 'MContact' });
};
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>
<style scoped lang="less">
.box-radius {
  position: fixed;
  right: 32px;
  top: 65%;
  z-index: 99;
}
.box {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 6px #ededed;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.phone {
  margin-top: 1rem;
}
</style>
