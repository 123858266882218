<template>
  <div class="top" @click="scrollToTop"></div>
</template>
<script setup>
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>
<style scoped lang="less">
.top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 80px;
  right: 80px;
  cursor: pointer;
  z-index: 999;
  border-radius: 50%;
  background: url(/public/image/top.svg);
  &::after {
    content: '';
    display: none;
    width: 40px;
    height: 40px;
    background: url('/public/image/top-red.svg');
  }
}
.top:hover {
  &::after {
    display: block;
  }
}
</style>
